<template>
  <div id="mainpricacy" class="mt-30">
    <div class="top__header">
      위치정보 이용약관
    </div>
    <div class="content-list pc-only">
      <div class="flex">
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('first')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 1 조 (목적) </p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'first' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'first'">
            이 약관은 남해군(운영사)가 운영하는 원스톱관광플랫폼 ‘낭만남해’와 관련하여 이용자가 서비스를 이용함에 있어 필요한 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('second')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 2 조 (이용약관의 효력 및 변경)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'second' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'second'">
            ① 본 약관은 서비스를 신청한 회원 또는 개인위치정보주체가 본 약관에 동의하고 낭만남해가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생한다.<br>
            ② 이용자가 온라인에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 본다.<br>
            ③ 낭만남해는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있다.<br>
            ④ 낭만남해가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 7일 전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이 이용자에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이후 상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 이용자에게 전자적 형태(전자우편, SMS 등)로 약관 개정 사실을 발송하여 고지한다. <br>
            ⑤ 낭만남해가 전항에 따라 이용자에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 본다. 이용자가 개정약관에 동의하지 않을 경우 이용자는 이용계약을 해지할 수 있다.<br>
          </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('third')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 3 조 (관계법령의 적용) </p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'third' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'third'">
            본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따른다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('fourth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 4 조 (서비스의 내용) </p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fourth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'fourth'">
            낭만남해가 제공하는 서비스는 아래와 같습니다. <br><br>
            서비스명 : 낭만남해<br>
            서비스내용 : 주변 입점업체 정보 제공<br>
            <p style="color:#0000ff">개인위치정보 보유기간 : 이용 목적 달성 시, 즉시 파기</p>
          </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('fifth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 5 조 (서비스 이용요금) </p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fifth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'fifth'">
            ① 낭만남해가 제공하는 서비스는 무료입니다. <br>
            ② 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사의 정책에 따른다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('sixth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 6 조 (서비스내용변경 통지 등)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'sixth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'sixth'">
            ① 낭만남해가 서비스 내용을 변경하거나 종료하는 경우 낭만남해는 이용자의 등록된 휴대전화번호로 SMS를 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있다.<br>
            ② ①항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트 등 기타 낭만남해의 공지사항을 통하여 이용자들에게 통지할 수 있다.
          </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('seventh')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 7 조 (서비스이용의 제한 및 중지)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'seventh' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'seventh'">
            ① 낭만남해는 아래 각 호에 해당하는 사유가 발생한 경우에는 이용자의 서비스 이용을 제한하거나 중지시킬 수 있습니다.<br>
            1. 이용자가 낭만남해 서비스의 운영을 고의 또는 중과실로 방해하는 경우<br>
            2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우<br>
            3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br>
            4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때<br>
            5. 기타 중대한 사유로 인하여 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우<br>
            ② 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 이용자에게 알려야 한다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('eighth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 8 조 (개인위치정보의 이용 또는 제공)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'eighth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'eighth'">
            ① 낭만남해는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 한다.<br>
            1. 위치기반서비스사업자의 상호, 주소, 전화번호 그 밖의 연락처<br>
            2. 개인위치정보주체 및 법정대리인(이 약관 제10조, 제11조 규정에 의하여 법정대리인의 동의를 얻어야 하는 경우로 한정함)의 권리와 그 행사방법<br>
            3. 위치기반서비스사업자가 제공하고자 하는 위치기반서비스의 내용<br>
            4. 위치정보 수집·이용·제공사실 확인자료의 보유근거 및 보유기간 <br>
            5. 본조 제4항에 규정된 통보에 관한 사항<br>
              ② 낭만남해는 다음 각호의 목적으로 개인위치정보를 최대 1년간 보유 및 이용할 수 있습니다. 이 경우 낭만남해는 이 약관 및 관계 법령이 정하는 바에 따라 개인위치정보보호절차를 준수합니다.<br>
              1. 이 약관 제4조에 따른 위치기반서비스를 제공하기 위한 목적<br>
              2. 낭만남해의 서비스 등록업체와 개인위치정보의 연계·결합·분석을 통한 시장조사, 상품·서비스(혜택) 연구개발, 이용안내 및 권유 등 판촉활동<br>
              3. 이용자의 민원처리 목적<br>
              ② 이용자 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.<br>
              ③ 낭만남해는 이용자의 개인위치정보를 제3자에게 제공하지 않습니다. 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. <br>
              ④ 낭만남해는 개인위치정보주체의 별도 동의가 있거나, 다음 각호에 해당하는 경우를 제외하고는 개인위치정보 또는 개인위치정보수집 ·이용 ·제공사실 확인자료를 이 약관 제4조 및 본조 제2항에 명시 또는 고지한 범위를 넘어 보존·이용하거나 제3자에게 제공하지 않습니다.<br>
              1. 이용자의 민원처리를 위하여 위치정보수집·이용·제공사실 확인자료가 필요한 경우<br>
              2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 이용·제공하는 경우<br>
          </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
          <div class="title" @click="toggleText('ninth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p> 제 9 조 (개인위치정보주체의 권리)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'ninth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'ninth'">
            ① 이용자는 낭만남해에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 낭만남해는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.<br>
            ② 이용자는 낭만남해에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 낭만남해는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.<br>
            ③ 이용자는 낭만남해에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 낭만남해는 정당한 사유 없이 회원의 요구를 거절할 수 없습니다.<br>
            1. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료<br>
            2. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용<br>
            ④ 이용자는 제1항 내지 제3항의 권리행사를 위해 낭만남해의 소정의 절차를 통해 요구할 수 있습니다.
          </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num10')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  제 10 조 (법정대리인의 권리) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num10' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num10'">
              ① 낭만남해는 14세 미만의 이용자에 대해서는 개인위치정보를 이용한 위치기반서비스 제공에 대한 동의를 당해 이용자와 당해 이용자의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 제9조에 의한 이용자의 권리를 모두 가집니다.<br>
              ② 낭만남해는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용․제공사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하는 경우에는 14세미만의 아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.<br>
              1. 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용, 제공사실 확인자료가 필요한 경우<br>
              2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
            </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
            <div class="title" @click="toggleText('num11')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  제 11 조 (8세 이하의 아동 등의 보호의무자의 권리)  </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num11' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num11'">
              ① 낭만남해는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.<br>
              1. 8세 이하의 아동<br>
              2. 금치산자<br>
              3. 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제29조의 규정에 의하여 장애인등록을 한 자에 한한다)<br>
              ② 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 낭만남해에 제출하여야 합니다.<br>
              ③ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num12')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p> 제 12 조 (개인위치정보의 보존 및 파기)</p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num12' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num12'">
              ① 낭만남해는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 위치정보 수집·이용·제공사실 확인자료를 위치정보시스템에 자동 기록·보존하며, 해당 자료는 1년간 보존합니다. 단, 관계 법령에 따라 보존할 의무 및 필요성이 있는 경우에는 그에 따라 보존합니다.<br>
              ② 이 약관 제4조 및 제8조2항의 목적을 달성한 때에 개인위치정보를 즉시 파기합니다.
            </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
            <div class="title" @click="toggleText('num13')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p> 제 13 조 (위치정보관리책임자의 지정) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num13' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num13'">
              ① 낭만남해는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영한다. <br>
              ② 위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num14')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>제 14 조 (손해배상) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num14' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num14'">
              ① 낭만남해가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 이용자에게 손해가 발생한 경우 이용자는 낭만남해에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 낭만남해는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.<br>
              ② 이용자가 본 약관의 규정을 위반하여 낭만남해에 손해가 발생한 경우 낭만남해는 회원에 대하여 손해배상을 청구할 수 있습니다. 이 경우 이용자는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
            </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
            <div class="title" @click="toggleText('num15')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  제 15 조 (면책) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num15' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num15'">
              ① 낭만남해는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
               1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
               2. 서비스 제공을 위하여 낭만남해와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우
               3. 이용자의 귀책사유로 서비스 이용에 장애가 있는 경우
               4. 제1호 내지 제3호를 제외한 기타 낭만남해의 고의∙과실이 없는 사유로 인한 경우
               ② 낭만남해는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니합니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num16')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>   제 16 조 (규정의 준용) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num16' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num16'">
              ① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.<br>
              ② 본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다.
            </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
            <div class="title" @click="toggleText('num17')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  제 17 조 (분쟁의 조정 및 기타) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num17' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num17'">
              ① 낭만남해는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.<br>
              ② 낭만남해 또는 이용자는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법 제43조의 규정에 의한 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num18')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p> 제 18 조 (운영사의 연락처) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num18' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num18'">
              운영사의 상호 및 주소 등은 다음과 같습니다.<br>
              1. 상 호 : 남해군청 관광경제국 관광진흥과<br>
              2. 주 소 : 경남 남해군 남해읍 망운로9번길 12<br>
              3. 대표전화 : 055-864-8644
            </div>
          </div>
        </div>
        <div class="half">
          <div class="content-box">
            <div class="title" @click="toggleText('num19')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  부 칙</p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num19' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num19'">
              제 1 조 (시행일) 본 약관은 2024년 05월 01일부터 시행합니다.<br>
              제 2 조 위치정보관리책임자는 2024년 05월 01일을 기준으로 다음과 같이 지정합니다.<br><br>
              소속 : (주) 유니드<br>
              연락처 : 055-761-9543<br>
              담당자 : 송기준
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-list mb-only">
      <div class="content-box">
          <div class="title" @click="toggleText('first')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 1 조 (목적) </p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'first' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'first'">
            이 약관은 남해군(운영사)가 운영하는 원스톱관광플랫폼 ‘낭만남해’와 관련하여 이용자가 서비스를 이용함에 있어 필요한 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('second')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 2 조 (이용약관의 효력 및 변경)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'second' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'second'">
            ① 본 약관은 서비스를 신청한 회원 또는 개인위치정보주체가 본 약관에 동의하고 낭만남해가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생한다.<br>
            ② 이용자가 온라인에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 본다.<br>
            ③ 낭만남해는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있다.<br>
            ④ 낭만남해가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 7일 전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이 이용자에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이후 상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 이용자에게 전자적 형태(전자우편, SMS 등)로 약관 개정 사실을 발송하여 고지한다. <br>
            ⑤ 낭만남해가 전항에 따라 이용자에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 본다. 이용자가 개정약관에 동의하지 않을 경우 이용자는 이용계약을 해지할 수 있다.<br>
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('third')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 3 조 (관계법령의 적용) </p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'third' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'third'">
            본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따른다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('fourth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 4 조 (서비스의 내용) </p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fourth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'fourth'">
            낭만남해가 제공하는 서비스는 아래와 같습니다. <br><br>
            서비스명 : 낭만남해<br>
            서비스내용 : 주변 입점업체 정보 제공<br>
            <p style="color:#0000ff">개인위치정보 보유기간 : 이용 목적 달성 시, 즉시 파기</p>
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('fifth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 5 조 (서비스 이용요금) </p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'fifth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'fifth'">
            ① 낭만남해가 제공하는 서비스는 무료입니다. <br>
            ② 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사의 정책에 따른다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('sixth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 6 조 (서비스내용변경 통지 등)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'sixth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'sixth'">
            ① 낭만남해가 서비스 내용을 변경하거나 종료하는 경우 낭만남해는 이용자의 등록된 휴대전화번호로 SMS를 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있다.<br>
            ② ①항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트 등 기타 낭만남해의 공지사항을 통하여 이용자들에게 통지할 수 있다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('seventh')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 7 조 (서비스이용의 제한 및 중지)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'seventh' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'seventh'">
            ① 낭만남해는 아래 각 호에 해당하는 사유가 발생한 경우에는 이용자의 서비스 이용을 제한하거나 중지시킬 수 있습니다.<br>
            1. 이용자가 낭만남해 서비스의 운영을 고의 또는 중과실로 방해하는 경우<br>
            2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우<br>
            3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br>
            4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때<br>
            5. 기타 중대한 사유로 인하여 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우<br>
            ② 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 이용자에게 알려야 한다.
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('eighth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>제 8 조 (개인위치정보의 이용 또는 제공)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'eighth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'eighth'">
            ① 낭만남해는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 한다.<br>
            1. 위치기반서비스사업자의 상호, 주소, 전화번호 그 밖의 연락처<br>
            2. 개인위치정보주체 및 법정대리인(이 약관 제10조, 제11조 규정에 의하여 법정대리인의 동의를 얻어야 하는 경우로 한정함)의 권리와 그 행사방법<br>
            3. 위치기반서비스사업자가 제공하고자 하는 위치기반서비스의 내용<br>
            4. 위치정보 수집·이용·제공사실 확인자료의 보유근거 및 보유기간 <br>
            5. 본조 제4항에 규정된 통보에 관한 사항<br>
              ② 낭만남해는 다음 각호의 목적으로 개인위치정보를 최대 1년간 보유 및 이용할 수 있습니다. 이 경우 낭만남해는 이 약관 및 관계 법령이 정하는 바에 따라 개인위치정보보호절차를 준수합니다.<br>
              1. 이 약관 제4조에 따른 위치기반서비스를 제공하기 위한 목적<br>
              2. 낭만남해의 서비스 등록업체와 개인위치정보의 연계·결합·분석을 통한 시장조사, 상품·서비스(혜택) 연구개발, 이용안내 및 권유 등 판촉활동<br>
              3. 이용자의 민원처리 목적<br>
              ② 이용자 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.<br>
              ③ 낭만남해는 이용자의 개인위치정보를 제3자에게 제공하지 않습니다. 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. <br>
              ④ 낭만남해는 개인위치정보주체의 별도 동의가 있거나, 다음 각호에 해당하는 경우를 제외하고는 개인위치정보 또는 개인위치정보수집 ·이용 ·제공사실 확인자료를 이 약관 제4조 및 본조 제2항에 명시 또는 고지한 범위를 넘어 보존·이용하거나 제3자에게 제공하지 않습니다.<br>
              1. 이용자의 민원처리를 위하여 위치정보수집·이용·제공사실 확인자료가 필요한 경우<br>
              2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 이용·제공하는 경우<br>
          </div>
          </div>
          <div class="content-box">
          <div class="title" @click="toggleText('ninth')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p> 제 9 조 (개인위치정보주체의 권리)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'ninth' }]"></i>
          </div>
          <div class="text" v-show="showtext === 'ninth'">
            ① 이용자는 낭만남해에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 낭만남해는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.<br>
            ② 이용자는 낭만남해에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 낭만남해는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.<br>
            ③ 이용자는 낭만남해에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 낭만남해는 정당한 사유 없이 회원의 요구를 거절할 수 없습니다.<br>
            1. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료<br>
            2. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용<br>
            ④ 이용자는 제1항 내지 제3항의 권리행사를 위해 낭만남해의 소정의 절차를 통해 요구할 수 있습니다.
          </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num10')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  제 10 조 (법정대리인의 권리) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num10' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num10'">
              ① 낭만남해는 14세 미만의 이용자에 대해서는 개인위치정보를 이용한 위치기반서비스 제공에 대한 동의를 당해 이용자와 당해 이용자의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 제9조에 의한 이용자의 권리를 모두 가집니다.<br>
              ② 낭만남해는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용․제공사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하는 경우에는 14세미만의 아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.<br>
              1. 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용, 제공사실 확인자료가 필요한 경우<br>
              2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num11')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  제 11 조 (8세 이하의 아동 등의 보호의무자의 권리)  </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num11' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num11'">
              ① 낭만남해는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.<br>
              1. 8세 이하의 아동<br>
              2. 금치산자<br>
              3. 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제29조의 규정에 의하여 장애인등록을 한 자에 한한다)<br>
              ② 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 낭만남해에 제출하여야 합니다.<br>
              ③ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num12')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p> 제 12 조 (개인위치정보의 보존 및 파기)</p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num12' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num12'">
              ① 낭만남해는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 위치정보 수집·이용·제공사실 확인자료를 위치정보시스템에 자동 기록·보존하며, 해당 자료는 1년간 보존합니다. 단, 관계 법령에 따라 보존할 의무 및 필요성이 있는 경우에는 그에 따라 보존합니다.<br>
              ② 이 약관 제4조 및 제8조2항의 목적을 달성한 때에 개인위치정보를 즉시 파기합니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num13')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p> 제 13 조 (위치정보관리책임자의 지정) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num13' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num13'">
              ① 낭만남해는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영한다. <br>
              ② 위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num14')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>제 14 조 (손해배상) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num14' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num14'">
              ① 낭만남해가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 이용자에게 손해가 발생한 경우 이용자는 낭만남해에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 낭만남해는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.<br>
              ② 이용자가 본 약관의 규정을 위반하여 낭만남해에 손해가 발생한 경우 낭만남해는 회원에 대하여 손해배상을 청구할 수 있습니다. 이 경우 이용자는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num15')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  제 15 조 (면책) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num15' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num15'">
              ① 낭만남해는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
               1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
               2. 서비스 제공을 위하여 낭만남해와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우
               3. 이용자의 귀책사유로 서비스 이용에 장애가 있는 경우
               4. 제1호 내지 제3호를 제외한 기타 낭만남해의 고의∙과실이 없는 사유로 인한 경우
               ② 낭만남해는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니합니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num16')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>   제 16 조 (규정의 준용) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num16' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num16'">
              ① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.<br>
              ② 본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num17')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  제 17 조 (분쟁의 조정 및 기타) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num17' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num17'">
              ① 낭만남해는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.<br>
              ② 낭만남해 또는 이용자는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법 제43조의 규정에 의한 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num18')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p> 제 18 조 (운영사의 연락처) </p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num18' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num18'">
              운영사의 상호 및 주소 등은 다음과 같습니다.<br>
              1. 상 호 : 남해군청 관광경제국 관광진흥과<br>
              2. 주 소 : 경남 남해군 남해읍 망운로9번길 12<br>
              3. 대표전화 : 055-864-8644
            </div>
          </div>
          <div class="content-box">
            <div class="title" @click="toggleText('num19')">
              <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
              <p>  부 칙</p>
              <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'num19' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'num19'">
              제 1 조 (시행일) 본 약관은 2024년 05월 01일부터 시행합니다.<br>
              제 2 조 위치정보관리책임자는 2024년 05월 01일을 기준으로 다음과 같이 지정합니다.<br><br>
              소속 : (주) 유니드<br>
              연락처 : 055-761-9543<br>
              담당자 : 송기준
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      showtext: false,
    };
  },
  methods: {
    toggleText(text) {
      this.showtext = (this.showtext === text) ? null : text;
    },
  },
};
</script>
